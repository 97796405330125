<template>
  <div class="view pa24">
    <el-button
      class="mr10"
      type="primary"
      v-if="jurisdictionA  || userInfo.roleId || userInfo.main"
      @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
      >添加视频</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="videoTitle" align="center" label="标题" show-overflow-tooltip/>
        <el-table-column align="center" prop="videoCover" label="封面">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.videoCover"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
         <el-table-column prop="sort" align="center" label="排序" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
              >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="delVideo(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="updateData(scope.row,'up')"
              icon="el-icon-info"
              icon-color="red"
              :title="'确定要上移这个视频吗'"
            >
              <el-button slot="reference" class="ml10" type="text" size="small">
                上移
              </el-button>
            </customPopconfirm>
              <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="updateData(scope.row,'down')"
              icon="el-icon-info"
              icon-color="red"
              :title="'确定要下移这个视频吗'"
            >
              <el-button slot="reference" class="ml10" type="text" size="small">
                 下移
              </el-button>
            </customPopconfirm>
             <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="delVideo(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
                  >删除</el-button
                >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加视频"
      :visible.sync="centerDialogVisible"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="视频标题" prop="videoTitle">
          <el-input v-model="ruleForm.videoTitle"></el-input>
        </el-form-item>
        <template v-if="bEdit">
          <el-form-item label="上传时间">
            <el-input disabled v-model="ruleForm.uploadTime"></el-input>
          </el-form-item>
          <el-form-item label="播放量">
            <el-input disabled v-model="ruleForm.playVolume"></el-input>
          </el-form-item>
          <el-form-item label="获客人数">
            <el-input disabled v-model="ruleForm.guestsNum"></el-input>
          </el-form-item>
          <el-form-item label="点赞">
            <el-input disabled v-model="ruleForm.likesNum"></el-input>
          </el-form-item>
          <el-form-item label="转发">
            <el-input disabled v-model="ruleForm.forwardNum"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="视频内容" prop="videoUrl">
          <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video
              v-if="ruleForm.videoUrl"
              :src="ruleForm.videoUrl"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过100M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频封面" prop="videoCover">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-cover': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCover"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="" v-if="!bEdit">
          <div
            class="d-flex w220 justify-content-between align-items-center select-goods-list"
            v-for="(item, index) in ruleForm.videoGoods"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>￥{{ item.price }}</div>
            <i class="el-icon-delete" @click="goodsDelete(index)"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择商品"
      :visible.sync="dialogDetailVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <commonTable
        :tableData="goodsList"
        :loading="goodsListLoading"
        @handleSizeChange="goodsListSizeChange"
        @handleCurrentChange="goodsListCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="goodsListCurrentPage"
      >
        <template v-slot:table>
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column
            prop="goodsId"
            align="center"
            label="产品id"
            show-overflow-tooltip
          />
          <el-table-column prop="goodsName" align="center" label="产品 " />
          <el-table-column prop="goodsTypeName" align="center" label="分类" />
          <el-table-column prop="price" align="center" label="价格" />
          <el-table-column prop="stockNum" align="center" label="库存" />
          <el-table-column prop="dealNum" align="center" label="总销量" />
          <el-table-column prop="createTime" align="center" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createTime | getDataTimeSec }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取 消</el-button>
        <el-button @click="queding" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPage,
  add,
  deleteById,
  updateData,
  update,
  getGoodsInfoPC,
} from "@/api/videoManage";
import { getDataTimeSec } from "@/utils";
import {moveList,addVideo,uodVideo,moveVideo,delVideo} from '@/api/videoManage.js'
let ac;
export default {
  props:['isShow','cardId'],
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        videoTitle: "",
        videoUrl: "",
        videoCover: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
        cardId:this.cardId
      },
      rules: {
        videoTitle: [{ required: true, message: "请输入视频标题", trigger: "blur" }],
        videoUrl: [{ required: true, message: "请上传视频内容" }],
        // cover: [{ required: true, message: "请上传视频封面" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(timeStamp) {
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
        1000 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
        1000 * 60 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
        1000 * 60 * 60 * 24 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
        milliseconds > 1000 * 60 * 60 * 24 * 15 &&
        year == now.getFullYear()
      ) {
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        timeSpanStr = year + "-" + month + "-" + day;
      }
      return timeSpanStr;
    },
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.ruleForm.videoCover !== "";
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  created() {
    this.getGoodsInfoPC();
  },
  mounted(){
    this.moveList();
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    async moveList() {

      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        queryType: 'web' ,
      };
      if(this.jurisdictionA){
        data.queryType = 'web'
        if(this.jurisdictionB){
          data.queryType = ''
        }
      }else if(this.jurisdictioB){
        data.queryType = ''
      }

      try {
        this.loading = true;
        const result = await moveList({cardId:this.cardId,companyId:this.$store.state.loginRoot.userInfo.companyId});
        this.loading = false;
        const { data } = result;
        
        // console.log(total)
        this.tableData = data;
        // this.total = total;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videoCover = "";
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };

      try {
        this.goodsListLoading = true;
        const result = await getGoodsInfoPC(data);
        this.goodsListLoading = false;
        const { total, list } = JSON.parse(result?.data?.pageInfo);
        this.goodsList = list;
        this.goodsListTotal = total;
      } catch (error) {
        this.goodsListLoading = false;
      }
      // const reslut
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        videoTitle: "",
        videoUrl: "",
        videoCover: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
      }),
        (this.fileList = []);
      this.bEdit = false;
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index){
      this.ruleForm.videoGoods.splice(index,1)
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          videoId: this.ruleForm.videoId,
          describes: item.describes,
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;

    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.videoUrl = `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 100MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCover(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.videoCover = `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${res.data}`;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm = data;
      console.log(this.ruleForm);
      this.ruleForm.type = b ? 1 : 2;
      if(data.videoCover){
       this.ruleForm.videoCover = data.videoCover;
       this.fileList = [{ url: data.videoCover, uid: 1 }];
      }else{
         this.ruleForm.videoCover = '';
         this.fileList = [];
      }
     
      this.bdetermine = b;
      this.bEdit = b;
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.ruleForm.suggestions == "") {
              delete this.ruleForm.suggestions;
            }
            let data = {
              ...this.ruleForm,
              link: "",
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              await uodVideo(data);
            } else {
              data.cardId=this.cardId
              console.log(data)
              await addVideo(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.dialogDetailVisible = false
            this.moveList()
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async delVideo(val) {
      try {
        await delVideo({ videoId: val });
        const reslut= await this.moveList();
        this.$message({
              message: '成功',
              type: "success",
            });
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 移动视频 */
    async updateData(val,type) {
      let sorts=[]
      for(var i=0;i<this.tableData.length;i++){
          if(this.tableData[i]['videoId']==val.videoId){
               if(type=='up'){
                 if(i==0){
                   sorts=[2,1,0]
                 }else if(i==1){
                   sorts=[1,2,0] 
                 }else if(i==2){
                   sorts=[2,0,1]
                 }
              }else if(type=='down'){
                  if(i==0){
                   sorts=[1,2,0]
                 }else if(i==1){
                   sorts=[2,0,1] 
                 }else if(i==2){
                   sorts=[2,1,0]
                 }
              }
          }
      }
      let data = {
        cardId: val.cardId,
        sorts:sorts
      };
      try {
        const result=await moveVideo(data);
        await this.moveList();
        this.$message({
              message: result.message,
              type: "success",
            });
      } catch (error) {
        this.$message({
              message: error.message,
              type: "success",
            });
      }
     
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-cover .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-cover .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
