<template>
  <div style="width:375px;" class="mb15">
    <div class="bgfff pl15 pt15 pr15 card2">
      <div class="posre h214 overhidden card-list">
        <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'" alt=""
            :class="card_msg.logo ? 'w225' : 'w100p' "
            style="box-sizing:border-box;padding-top:1rpx;padding-bottom:2rpx;"
            class="posab right0 top0 card2_user h100p w225">

        <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_lefttext.png" alt="" class="posab left0 top0 card_bg">
        <div class="posab h100p w200 top0 left0 zindex-99 card-information">
          <div class="mb15">
            <p class="fs20 lh1 mb10 c38 fbold pb3">{{card_msg.name || '姓名'}}</p>
            <p class="fs12s lh1 c38">{{card_msg.position || '职位'}}</p>
          </div>

          <div class="ca8 mb5 nowrap">
            <div class="disflex align-cen  mb5">
              <img :src="iconEmail" class="w12 h12 mr5">
              <p class="fs12s">{{card_msg.phone || '电话'}}</p>
            </div>
            <div class="disflex align-cen">
              <img :src="iconPhone" class="w12 h12 mr5">
              <p class="fs12s">{{card_msg.email || '邮箱'}}</p>
            </div>
            
          </div>
          <span class="pr14 fs14s pb15">{{card_msg.companyName || '公司名称'}}</span>
        </div>
        <div class="card2_inner nowrap">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    name: "card_3",
    props:['card_msg', 'isPrev'],
    data(){
      return{
        card2_logo : 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card2_user.png',
        iconEmail: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/ic_email.png',
        iconPhone: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/ic_phone.png',
      }
    },
    methods:{
      previewImage(url){
        if(!this.isPrev) return;

        let urls = [url];

        wx.previewImage({
          current: urls[0],
          urls: urls
          // urls必须是数组 不然会报错
        })
      },
      makePhone(tel){//拨打电话
        if(tel){
          util.MakePhone(tel);
        }
      },
    }
  }
</script>

<style>
  .card2 .card_bg {
    width: 200px;
    height: 100%;
  }

  .card2 .card2_inner {
    position: absolute;
    left:162px;
    top: 52px;
  }

  .card_line_2 {
    position: relative;
  }

  .card_line_2::before,.card_line_2::after {
    position: absolute;
    width: 3px;
    height: 8px;
    background: #fff;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
  }

  .card_line_2::before {
    right: 0;
  }

  .card_line_2::after {
    right: 5px;
  }

  .card_company {
    position: absolute;
    right: 17px;
    bottom: 15px;
  }
</style>
<style  scoped>
.card-information{
  padding:40px 0 0 15px; 
}
.card-list{
 border-radius:20px;
 box-shadow: 0 0 25px rgba(56, 56, 56, 0.05);
}
</style>
