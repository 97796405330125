<template>
  <div style="width:375px">
    <div class="top_card fs12s mb15 shadow_gray pb28" >
      <div class="pl34 pt25 cblue fs12s"><span class="before_line_3">{{card_msg.companyName || '企业名称'}}</span></div>
      <div class="top_msg disflex pt25 jsbet"  @click="page_turn('index')">
        <div>
          <p class="fbold c38 fs18">{{card_msg.name||'姓名'}}</p>
          <p class="fs12s ca8 pt10 pb23">{{card_msg.position || '职位'}}</p>
          <p class="fs12s c78">» {{card_msg.phone || '电话'}}</p>
          <p class="fs12s c78">» {{card_msg.email || '邮箱'}}</p>
        </div>
        <img :src="card_msg.logo ||'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'"  mode="aspectFill" alt="" class="w60 h60 bradius5" />
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "card_1",
    props:['card_msg','type','hasCard','isdel','index'],
    data(){
      return{
        card1_logo:'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card1_user.png'
      }
    },
    methods:{
      page_turn(url){
        this.$emit('page_turn',url,this.card_msg.companyId , this.card_msg.cardId)
      },
      moreTap(){
        this.$emit('moreTap' , this.card_msg.recordId, this.card_msg.cardId, this.isdel);
      },
      makePhone(){//拨打电话
        if(this.card_msg.tel){
          wx.makePhoneCall({
            phoneNumber: this.card_msg.tel,
          })
        }else{
          wx.showToast({
            title: '还没有添加电话呢！',
            duration: 2000,
            icon: 'none'
          });
        }

      },
      share(companyId,cardId){
        this.$emit('shareApp',companyId,cardId);
      }
    }
  }
</script>

<style scoped>

</style>
