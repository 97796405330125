<template>
  <el-form label-width="90px">
    <el-form-item label="等级">
      <el-select v-model="royaltyId" placeholder="请选择">
        <el-option v-for="item in list" :key="item.royaltyId" :label="item.lavelName" :value="item.royaltyId"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {getCompanyRoyalty, setEmployeeLevel} from "@/api/companyManage.js"
export default {
  props: ['empId'],
  data() {
    return {
      list: [],
      royaltyId: ''
    }
  },
  created() {
    getCompanyRoyalty().then((res) => {
      this.list = res.data
    })
  },
  methods: {
    async submit() {
      if (this.royaltyId) {
        await setEmployeeLevel({
          royaltyId: this.royaltyId,
          empId: this.empId
        })
        this.$emit('success')
      }
    }
  }
}
</script>

<style>

</style>