<template>
<div style="width:375px">
    <div class="bgfff pl15 pt15 pr15 card4">
        <div class="posre w100p h100p card4-box">
            <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'"
                  mode="aspectFill"
                  class="card4_bg">
            <p class="fs20 mt15 mb14 c38 fbold">{{card_msg.name || '姓名'}}</p>
            <p class="fs14s mb10 c78">{{card_msg.position || '职位'}}</p>
            <span class="card4-company fs12s cblue">{{card_msg.companyName || '公司名称'}}</span>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "card_4",
        props:['card_msg'],
        data(){
            return{
                card2_logo : 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card2_user.png',
            }
        }
    }
</script>

<style>
    .card_line_2 {
        position: relative;
    }

    .card_line_2::before,.card_line_2::after {
        position: absolute;
        width: 6upx;
        height: 16upx;
        background: #fff;
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
    }

    .card_line_2::before {
        right: 0;
    }

    .card_line_2::after {
        right: 10upx;
    }

    .card_company {
        position: absolute;
        right: 68upx;
        bottom: 30upx;
    }

    .card4 .cacrd4_left {
        position: absolute;
        left: 106upx;
        top: 96upx;
    }

    .card4 .card4_right {
        position: absolute;
        left: 414upx;
        top: 98upx;
    }
</style>
<style scoped>
    .card4{
        height: 540upx;
    }
    .card4-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card4_bg{
        border-radius: 50%;
        width: 125px;
        height: 125px;
        border: 5px solid #fff;
        box-shadow:0px 4px 16px 0px rgba(56,56,56,0.1);
        margin-top: 15px;
    }
    .card4-company{
        padding:6px 15px;
        background:rgba(229,248,247,1);
        border-radius: 15px;
    }
</style>
