<template>
    <div style="width:375px">
    <div class="posre pt283 bgfff pb15">
        <img :src="card_msg.logo ||'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'"  class="w100p h375 posab left0 top0" />
        <div class="pl15 pr15 posre">
            <div class="shadow_gray bgfff pl25 pt25 pr22 pb21 disflex jsbet ">

                <div>
                    <p class="fs18 c38 fbold pb7">{{card_msg.name || '姓名'}}</p>
                    <p class="fs14s c78 pb15">{{card_msg.position || '职位'}}</p>
                    <p class="disflex align-cen">
                        <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/tel_blue.png" alt="" class="w16 h16 mr11" />
                        <span class="fs12s ca8">{{card_msg.phone || '电话'}}</span>
                    </p>
                </div>
                <div class="disflex align-cen">
                    <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'" alt="" mode="aspectFill" class="w60 h60"
                    /><!--@click.stop="uploadpic('company')"-->
                </div>


            </div>
        </div>
    </div>
    </div>
</template>

<script>

    export default {
        name: "card_2",
        props:['card_msg'],
        data(){
            return{
                card1_logo : 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card1_user.png',
                card1_company:'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card1_logo.png'
            }
        },
        methods:{
            uploadpic(type){
                this.$emit('uploadpic',type)
            }
        }
    }
</script>

<style>

</style>
