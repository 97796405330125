<template>
  <div style="width:375px">
    <div class="bgfff pb15 card7">
        <div class="posre h426 boxshadow ">
            <img v-if='src' :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'" alt=""
                 mode="aspectFill"
                 class="posab left0 top0 card7_bg w100p h426">
          <div class="card7-mark">

          </div>
          <div class="card7_information"> 
            <div class="card7_right cblk">
              <span class="fs12s cblk ">{{card_msg.companyName || '公司名称'}}</span>
            </div>
            <div class="cacrd7_left mb12 ml-12 mt5">
              <p class="fs20 cfff fbold">{{card_msg.name || '姓名'}}</p>
              <p class="fs14s cfff lh15 mt5">{{card_msg.position || '职位'}}</p>
            </div>
          </div>
           <div>
            <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'" alt="" mode="aspectFill"
                 class="imgheader7 cacrd7_header">
           </div>
        </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "card_7",
        props:['card_msg'],
        data(){
            return {
              userlogo:"https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/user_default_avatar_square.png", //头像
              src:'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card7_bg.png'
            }
        },
      methods:{
      }

    }
</script>

<style>

  .card7_bg{
  }
  .boxshadow{
    background:rgba(255,255,255,1);
    box-shadow:0px 0px 10px 0px rgba(6, 1, 0, 0.35);
  }
  .card7_right {
    
  }
/* 
  @media screen and (max-width: 375px) {
    .cacrd7_header{
      position: absolute;
      top:260upx;
      left: 86upx;
    }
    .imgheader7{
      width:95upx;
      height:92upx;
      border-radius:5px;
    }
    .cacrd7_left {
      line-height: 38upx;
      position: absolute;
      right:140upx;
      text-align: right;
      bottom:65upx;
    }
  }

  @media screen and (min-width: 414px) {
    .cacrd7_header{
      position: absolute;
      top: 260upx;
      left: 87upx;
    }
    .imgheader7{
      width:94upx;
      height:94upx;
      border-radius:5px;
    }
    .cacrd7_left {
      text-align: right;
      line-height: 38upx;
      position: absolute;
      right: 140upx;
      bottom:70upx;
    }
  }
  @media screen and (min-width: 411px) {
    .cacrd7_header{
      position: absolute;
      top: 260upx;
      left: 87upx;
    }
    .imgheader7{
      width:94upx;
      height:94upx;
      border-radius:5px;
    }
    .cacrd7_left {
      text-align: right;
      line-height: 38upx;
      position: absolute;
      right: 140upx;
      bottom:70upx;
    }
  } */
  
</style>
<style scoped>
.cacrd7_header{
  position: absolute;
  bottom: -15px;
  left: 17px;
  width:76px;
  height:76px;
  background:rgba(191,191,191,1);
  border:3px solid rgba(255,255,255,1);
  box-shadow:0px 4px 16px 0px rgba(56,56,56,0.1);
  border-radius:50%;
}
.card7_information{
  position: absolute;
  width: 240px;
  height: 100%;
  left: 105px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.card7_right span{
  display: block;
  margin: 20px 15px 0 0;
  padding:5px 15px;
  background: rgba(0,0,0,0.7);
  border-radius: 22px;
  color: #ffffff;
  text-align: center;
  line-height: 22px;
  float: right;
}
.card7-mark{
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}
</style>
