<template>
  <div class="view pa24">
    <div class="view mr mb20">
      <span style="color: #8d97ad; font-size: 14px" v-if="userInfo.roleId">设置部门和管理员流程:①请先在下方成员列表中选择相应员工,点击“编辑" , 设置为管理员------②在“部门设置"中,新增部门,选择相应管理员------③如需设置管理员相关权限,可在"管理员列表”里编辑修改管理员权限</span
      >
    </div>
    <el-row :gutter="24">
      <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
        <div class="d-flex align-items-center">
          <el-input
             v-model="searchKey"
             class="mb10"
             style="width: 240px"
             placeholder="请输入员工姓名"
          />
          <el-button class="ml10 mb10" type="primary" @click="getCorporator">搜索</el-button>
          <!-- <el-button class="ml10 mb10">导出</el-button> -->
        </div>
        <div class="d-flex align-items-center">
          <el-button type="primary" 
                     class="mb10" 
                     v-if="userInfo.roleId || jurisdictionA || userInfo.main" 
                    @click="sectorSetup">部门设置</el-button>
          <el-button type="primary" 
                     class="mb10" 
                     v-if="userInfo.roleId || jurisdictionB || userInfo.main" 
                     @click="AdministratorList">管理员列表</el-button>
        </div>
      </el-col>
      
    </el-row>
    <commonTable :tableData="tableData" 
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column prop="jobNumber" width="100px" align="center" label="工号" />
        <el-table-column prop="employeeName" align="center" label="姓名" />
        <el-table-column
          prop="position"
          align="center"
          label="职位"
          show-overflow-tooltip
        />
        <el-table-column prop="departmentName" align="center" label="部门" />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="phone" align="center" label="手机" />
        <el-table-column prop="lavelName" align="center" label="分销等级" />
        <el-table-column prop="character" width="100px" align="center" label="角色" />
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column prop="monopolismCus" align="center" label="独占客户数" />
        <el-table-column prop="dealCus" align="center" label="成单客户数" />
        <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="edit_tap(scope.row)">编辑</el-button>
              <el-button type="text" @click="detall_tap(scope.row.jobNumber)">详情</el-button>
              <el-button type="text" @click="handleSetLevel(scope.row)">分销等级设置</el-button>
            </template>
            <!-- <el-button type="text" style="color: #4484fd">编辑</el-button> -->
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="编辑成员"
      center
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="50%"
    >
      <!-- <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      > -->
      <el-form
        label-width="auto"
        class="ruleForm d-flex flex-column"
      >
      <el-form-item prop="photos" label="名片样式">
         <div v-show="card_style == 0">
            <Card_1 :card_msg="memberInfo"></Card_1>
          </div>

          <div v-show="card_style == 1">
            <Card_2 :card_msg="memberInfo"></Card_2>
          </div>

          <div v-show="card_style == 2">
            <Card_3 :card_msg="memberInfo"></Card_3>
          </div>

          <div v-show="card_style == 3">
            <Card_4 :card_msg="memberInfo"></Card_4>
          </div>
          <!--2.0开始-->
          <div v-show="card_style == 4">
            <Card_5 :card_msg="memberInfo"></Card_5>
          </div>
          <div v-show="card_style == 5">
            <Card_6 :card_msg="memberInfo"></Card_6>
          </div>
          <div v-show="card_style == 6">
            <Card_7 :card_msg="memberInfo"></Card_7>
          </div>
          <div class="disflex">
            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card1.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 0 ? 'bblue' : 'bfff' "
                @click="card_style_change(0)">
            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card6.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 1 ? 'bblue' : 'bfff' "
                @click="card_style_change(1)">

            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card3.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 4 ? 'bblue' : 'bfff' "
                @click="card_style_change(4)">

            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card4.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 5 ? 'bblue' : 'bfff' "
                @click="card_style_change(5)">
            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card5.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 6 ? 'bblue' : 'bfff' "
                @click="card_style_change(6)">

            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card2.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 2 ? 'bblue' : 'bfff' "
                @click="card_style_change(2)">
            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/img_card7.png"
                alt=""
                class="w50 h50 mr10 bradius5 borderbox"
                :class="card_style == 3 ? 'bblue' : 'bfff' "
                @click="card_style_change(3)">
          </div>
       </el-form-item>
       <el-form-item label="选择模板" prop="cardTemplate">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.cardTemplate" value="DEFAULT" label="DEFAULT">基础默认版</el-radio>
            <el-radio v-model="memberInfo.cardTemplate" value="LUXURY" label="LUXURY" >豪华升级版</el-radio>
          </div>
       </el-form-item>
         <el-form-item label="公司">
          <el-select
            v-model="memberInfo.showBranch"
            style="width: 240px"
            :disabled="setDepartment"
            placeholder="请选择"
          >
          <el-option :label="this.companyName" @click.native ="changeCompanyName(0)" :value="0"></el-option>
          <!-- <el-option :label="this.branchCompanyName" v-if="this.branchCompanyName" @click.native ="changeCompanyName(1)" :value="1"></el-option> -->
          <el-option v-for="item in branchCompanyArray" :key="item.value" :value="item.value" :label="item.label" @click.native ="changeCompanyName(item)"></el-option>
          </el-select>
        </el-form-item>
       <!-- <el-form-item label="名片显示分公司" prop="showBranch">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.showBranch" :label="1">是</el-radio>
            <el-radio v-model="memberInfo.showBranch" :label="0" >否</el-radio>
          </div>
        </el-form-item> -->
       <el-form-item label="是否更新到人脉集市" prop="isPublic">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.isPublic" :label="true">是</el-radio>
            <el-radio v-model="memberInfo.isPublic" :label="false" >否</el-radio>
          </div>
       </el-form-item>
       <el-form-item prop="logo" label="头像">
        <div style="display: flex">
          <el-upload :action="uploadUrl" 
                        list-type="picture-card" 
                        :class="{ disabled: uploadDisabled }"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess" 
                        :file-list="fileList" 
                        :limit="1">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">上传图片</div>
              </el-upload>
        </div>
      </el-form-item>
        <el-form-item label="工号">
          <el-input
            readonly
            style="width: 240px"
            v-model="memberInfo.jobNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input style="width: 240px" v-model="memberInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-input style="width: 240px" v-model="memberInfo.position"></el-input>
        </el-form-item>
        <el-form-item label="微信号">
          <el-input
            style="width: 240px"
            v-model="memberInfo.personalWx"
          ></el-input>
        </el-form-item>
         <el-form-item label="邮箱">
          <el-input style="width: 240px" v-model="memberInfo.email"></el-input>
        </el-form-item>
        <el-form-item label="名片展示手机号">
          <el-input
            type="number"
            maxlength="11"
            readonly 
            style="width: 240px"
            v-model="memberInfo.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-select
            v-model="memberInfo.departmentId"
            style="width: 240px"
            :disabled="setDepartment"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in departmentList"
              :key="index"
              :label="item.name"
              :value="item.departmentId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-if="memberInfo.userType !== 2">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="setAdministrator"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="setAdministrator">管理员</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-else>
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="memberInfo.userType == 2"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="memberInfo.userType == 2">管理员</el-radio>
          </div>
        </el-form-item>
         <el-form-item label="个人简介">
            <el-input
              v-model="memberInfo.briefIntroduction"
              type="textarea"
              size="medium"
              :rows="6"
              placeholder="请输入"
            ></el-input>
        </el-form-item>
        <el-form-item prop="photos" label="图片展示">
        <div style="display: flex">
          <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove2"
            :on-success="handleSuccess2"
            :file-list="photos"
            :multiple="true"
            :limit="0"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>
       <el-form-item label="视频展示" prop="details">
        <el-button size="medium" @click="isShow = true">视频设置</el-button>
      </el-form-item> 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirm_tap"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
            :modal-append-to-body="false"
            title="提示"
            :visible.sync="isShow"
            width="80%"
            height="100%"
            v-if="isShow"
          >
           <enterpriseVideoList :isShow="isShow" :cardId="memberInfo.cardId"></enterpriseVideoList>
        </el-dialog>

    <el-dialog :visible.sync="showSetLevel" title="分销等级设置" append-to-body destroy-on-close>
      <SetLevel :empId="setLevelEmpId" @success="handleSetLevelSuccess"></SetLevel>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchCorporator,queryPageEmployee,queryEmployee,queryPageDepartment,updateEmployee } from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils/index";
import { mapState } from 'vuex';
import Card_1 from '@/components/card/card_1' //
import Card_2 from '@/components/card/card_2' //
import Card_3 from '@/components/card/card_3' //
import Card_4 from '@/components/card/card_4' //
import Card_5 from '@/components/card/card_5' //
import Card_6 from '@/components/card/card_6' //
import Card_7 from '@/components/card/card_7' //
import enterpriseVideoList from './enterpriseVideoList.vue'
import SetLevel from './components/SetLevel'

function toHump(name) {
    return name.replace(/\_(\w)/g, function(all, letter){
        return letter.toUpperCase();
    });
}

export default {
  name: "enterpriseMember",
  components: {
    SetLevel,
    commonTable,
    enterpriseVideoList,
    Card_1, Card_2, Card_3, Card_4, Card_5, Card_6, Card_7
  },
  data() {
    return {
      showSetLevel: false,
      setLevelEmpId: '',
      card_style: '',//名片样式
      uploadUrl:this.$store.state.uploadingUrl,
      isShow:false,
      tableData: [],
      departmentOptions: [{ value: "1", lable: "普通成员" }],
      dialogVisible: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      searchKey:'', //关键字
      jurisdictionA:'',
      jurisdictionB:'',
      jurisdictionC:'',
      jurisdictionD:'',
      jurisdictionF:'',
      userInfo: this.$store.state.loginRoot.userInfo,
      departmentList:[],
      ossUrl:this.$store.state.ossUrl,
      memberInfo:{
        logo:'',
        email:'',
        showBranch:'',
        photo:'',
        position:'',
        phone:'',
        personalWx:'',
        jobNumber:'',
        position:'',
        departmentId:'',
        userType:'',
        queryType:'', //请求类型
        briefIntroduction:'',
        cardTemplate:''
      },
      setAdministrator:false, // 是否可设置管理员
      setDepartment:false,// 是否可设置部门
      photos:[],
      fileList:[],
      branchCompanyName:'',
      companyName:'',
      branchCompanyArray:[],
      
    };
  },
  computed: {
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
  },
  watch:{
    routerArr(val){
      this.jurisdictionA = val.some(item => ['0210','02100','02101'].includes(item))//部门管理
      this.jurisdictionB = val.includes('0211')//管理员设置
      this.jurisdictionC = val.includes('0212')//成员设置
      this.jurisdictionD = val.some(item => ['02100'].includes(item))//部门管理(自己)
      this.jurisdictionF = val.some(item => ['02101'].includes(item))//部门管理(所有)      
      if(this.jurisdictionD){
        this.queryType = 'section'
        if(this.jurisdictionF){
          this.queryType = 'all'
        }
        this.getCorporator()
      }
      if(this.jurisdictionF){
        this.queryType = 'all'
        this.getCorporator()
      }
    },
    jurisdictionB(val){
      if(this.userInfo.roleId){
        this.setAdministrator = false 
      }else{
        this.setAdministrator = val ? false : true
      } 
      
    },
    jurisdictionF(val){
      if(this.userInfo.roleId){
        this.setDepartment = false 
      }else{
        this.setDepartment = val ? false : true
      } 
      
    }
  },
  filters: {
    dateFilter(val) {
      console.log(val);
    },
  },
  mounted() {
    this.getCorporator();
  },
  methods: {
    handleSetLevel(item) {
      this.setLevelEmpId = item.empId
      this.showSetLevel = true
    },
    handleSetLevelSuccess() {
      this.showSetLevel = false
      this.getCorporator()
    },
     handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = []
    },
     handleRemove2(file, photos) {
      this.photos = photos
      this.memberInfo.photo=this.photos.map((item) => item.url).join(",")
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
     handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.memberInfo.logo = this.ossUrl +  file.response.data 
      console.log(this.memberInfo)
      this.fileList = fileList;
    },
      handleSuccess2(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.photos = arr;
      this.memberInfo.photo = this.photos
      this.memberInfo.photo=this.memberInfo.photo.map((item) => item.url).join(",")
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    //获取企业成员
    getCorporator(Type) {
      let data = {
        pageNum:this.pageNum, 
        pageSize:this.pageSize,
        name:this.searchKey,
        queryType:this.$store.state.loginRoot.userInfo.roleId ? 'all' : this.queryType ? this.queryType : 'single'
      }
      queryPageEmployee(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.dataList.map(item => {
            const el = Object.entries(item).map(([k, v]) => {
              const obj = {}
              obj[toHump(k)] = v
              return obj
            }).reduce((pre, next) => ({...pre, ...next}),{})
            return el
          });
          console.log(this.tableData)
          this.total = res.data.total
          this.tableData.forEach((v) => {
            v.createTime = getDataTimeSec(v.createTime);
            if(v.userType == 0 ){
              v.character = '员工'
            }else if(v.userType == 1){
              v.character = '管理员'
            }else{
              v.character = 'BOSS'
            }
          });
        })
        .catch((err) => {
          this.tableData = []
          this.loading = false;
          console.log(err);
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getCorporator();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getCorporator();
    },
    //请求员工详情
    async queryEmployee(id){
      let data = {
        empId: id
      }
      let result = await queryEmployee(data)
      if(result.data.routers){
        result.data.routers = JSON.parse(result.data.routers || [])
      }
      this.card_style=result.data.style
      this.memberInfo = result.data
      this.photos = result.data.photo?result.data.photo.split(",").map((item, index) => {
              return {
                url: item,
                uid: index,
              };
        }):[];
      this.fileList = result.data.logo?[{url:result.data.logo,uid:1}]:[]
      //公司显示切换  
      this.branchCompanyArray=[]
      this.companyName=this.memberInfo.companyName
      if(this.memberInfo.branchCompanyName){
           var branchCompanyName= JSON.parse(this.memberInfo.branchCompanyName)  
           for(var i=0;i<branchCompanyName.length;i++){
              var arr={value:'',label:''}
              arr.value=branchCompanyName[i].id
              arr.label=branchCompanyName[i].branchCompanyName
              this.branchCompanyArray.push(arr)
            if(branchCompanyName[i].id===this.memberInfo.showBranch){
                  this.memberInfo.companyName=branchCompanyName[i].branchCompanyName
              }
          }
      }
      console.log(this.memberInfo,'info')
    },
    //请求部门信息
    getPageDepartment() {
      let data = {
        pageSize: 999,
      };
      queryPageDepartment(data)
      .then((res) => {
        this.departmentList = res.data.pageInfo.list || "";
      })
      .catch((err) => {
        this.departmentList = [];
      });
    },

    detall_tap(id){
      this.$router.push('./memberDetall?id=' + id)
    },
    edit_tap(item){
      if(!this.userInfo.roleId){
        if(item.userType == 0 ){
          if(!this.jurisdictionC){
            return this.$message({
              message: "暂无权限",
              type: "warning",
            });
          }

        }else if(item.userType == 1){
          if(!this.jurisdictionB){
            return this.$message({
              message: "暂无权限",
              type: "warning",
            });
          }
        }else if(item.userType == 2 ){
          return this.$message({
            message: "BOSS信息无法修改",
            type: "warning",
          });
        }
      }
      
      
      if(this.jurisdictionC || this.userInfo.roleId ){
        this.queryEmployee(item.empId)
        this.getPageDepartment()
        this.dialogVisible = true
      }else{
        return this.$message({
          message: "暂无权限",
          type: "warning",
        });
      }
      
    },
    sectorSetup(){
      this.$router.push('/sectorSetup')
    },
    AdministratorList(){
      this.$router.push('/AdministratorList')
    },
    affirm_tap(){
      this.memberInfo.style=this.card_style
      if(this.memberInfo.userType == 0){
       this.memberInfo.routers = []
      }else{
       this.memberInfo.routers = ['022','0213','04','040','041','08']
      }
      console.log(this.memberInfo)
      updateEmployee(this.memberInfo)
      .then(res=>{
        this.dialogVisible = false
        this.getCorporator()
      })
      
    },
   card_style_change (type) {//切换名片样式
      this.card_style = type
   },
   changeCompanyName(res){
     this.memberInfo.companyName=res.label
     if(res==0){
       this.memberInfo.companyName=this.companyName
     }
     console.log(res)
  },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>