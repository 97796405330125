<template>
<div style="width:375px">
    <div class="bgfff pl15 pt15 pr15 pb15 card5">
        <div class="posre h426 overhidden boxshadow">
            <img src="https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/card5_bg.png" alt=""
                 class="posab left0 top0  w100p h426 imgborder">

          <div class="align-cen fs12s ce8 card_company disflex">
            <div class="card_line_2"></div>
            <span class="pr14 fs12s">{{card_msg.companyName || '公司名称'}}</span>
          </div>

          <div class="cacrd5_header" >
            <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'" alt=""
                 mode="aspectFill"
                 class="imgheader">
          </div>

          <div class="cacrd3_right" >
            <p class="fs20 fbold">{{card_msg.name || '姓名'}}</p>
            <p class="fs12s lh20 ">{{card_msg.position || '职位'}}</p>
          </div>

          <div class="cacrd3_telemail " >
              <p class="fs12s  pb6" style="line-height:1;">{{card_msg.phone || '电话'}}</p>
              <p class="fs12s " style="line-height:1;">{{card_msg.email || '邮箱'}}</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "card_5",
        props:['card_msg','type'],
        data(){
          return {
            userlogo: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/user_default_avatar_square.png', //头像
            cardMsg : {}
          }
        },created() {
        }
    }
</script>

<style>
    div {
  color: #e9dabf;
}
.boxshadow {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 0px rgba(6, 1, 0, 0.35);
  border-radius: 20px;
}

.imgborder {
  border-radius: 10px;
}

.card_line_2 {
  position: relative;
  color: #e9dabf;
  margin-right: 2px;
}

.card_line_2::before,
.card_line_2::after {
  position: absolute;
  width: 2px;
  height: 9px;
  background: #e9dabf;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 12px;
  content: "";
}

.card_line_2::before {
  right: 0;
}

.card_line_2::after {
  right: 10upx;
}

.card_company {
  position: relative;
  top: 30px;
  left: 25px;
  font-size: 12px;
  letter-spacing: 1px;
}
.card5 .cacrd3_right {
  position: absolute;
  right: 60px;
  text-align: left;
  top: 92px;
  width: 100px;
}
@media screen and (min-width: 375px) {
  .card5 .cacrd5_header {
    position: absolute;
    right: 111px;
    top: 32px;
  }
  .card5 .imgheader {
    width: 47px;
    height: 47px;
    border-radius: 5px;
  }

  .card5 .cacrd3_telemail {
    position: absolute;
    text-align: left;
    right: 0;
    bottom: 30px;
    width: 140px;
  }
}


</style>
