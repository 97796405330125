<template>
<div style="width:375px">
    <div class="bgfff pl15 pt15 pr15 pb15 card3">
    <div class="posre h426 overhidden boxshadow">
      <img :src="card_msg.logo || 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201905%2F17%2F20190517211730_kurtr.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652170785&t=afd0480fa96114b1da905d27876e860b'"
           alt=""
           mode="aspectFill"
           class="posab left0 top0 card3_bg w100p h426 ">
      <div class="posab information">
        <div class="information-top borderbox h50p">
          <div>
            <img :src="iconDown" class="w8 h7">
          </div>
          <span class="lh1 fs12s cfff">{{card_msg.companyName || '公司名称'}}</span>
        </div>
        <div class="information-bottom borderbox h50p pt40">
          <p class="fs20 lh1 mb10 cfff fbold ">{{card_msg.name || '姓名'}}</p>
          <p class="fs14s lh1 cfff">{{card_msg.position || '职位'}}</p>  
        </div>
        
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    name: "card_6",
    props:['card_msg'],
    data(){
      return {
        userlogo: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/user/user_default_avatar_square.png', //头像
        iconDown: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crmRevision/ic_down.png',
      }
    }
}
</script>

<style>
div{
color: #383838;
}
.boxshadow{
background:rgba(255,255,255,1);
box-shadow:0px 0px 10px 0px rgba(6, 1, 0, 0.35);
border-radius:20px;
}

.card3_bg{
border-radius:10px;
}


.card3 .cacrd5_header{
  position: absolute;
  left: 120upx;
  top: 91upx;
}

.card3 .cacrd5_left {
  position: absolute;
  right :120upx;
  top:158upx;
  width: 200upx;
}
.card3 .card5_right {
  position: absolute;
  right: 160upx;
  top: 50upx;
  width: 200upx;
}

.card3 .imgheader{
  width:98upx;
  height:98upx;
  border-radius:50%;
}
</style>

<style scoped>
.information{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
}
.information-top{
  padding-top: 20upx;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.information-bottom{
  padding-bottom: 40upx;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
</style>
